console.log('Welcome to MathAround!');

import LogRocket from 'logrocket';

LogRocket.init('fblq6c/matharound');
LogRocket.identify('unknown', { beforeAuth: true });

import 'animate.css';
import './matharound.scss';
import 'bootstrap';

import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VuexEasyFirestore from 'vuex-easy-firestore';

Vue.use(VueRouter);
Vue.use(Vuex);

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyCZdcPtudWBjZdEGJp4ScFSzCpDdLNoQXU',
    authDomain: 'matharound-spa.firebaseapp.com',
    databaseURL: 'https://matharound-spa.firebaseio.com',
    projectId: 'matharound-spa',
    storageBucket: 'matharound-spa.appspot.com',
    messagingSenderId: '179855531906',
    appId: '1:179855531906:web:a0c6e926d1b388c87cf99b',
    measurementId: 'G-DBWSQ5GSFX'
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faPlayCircle,
    faBooks,
    faBookOpen,
    faThumbsUp,
    faSpinner,
    faAtom,
    faAtomAlt,
    faChalkboardTeacher,
    faUserUnlock,
    faUserLock
} from '@fortawesome/pro-duotone-svg-icons';
import { faClosedCaptioning } from '@fortawesome/pro-light-svg-icons';
import { faCircle as fasCircle, faCertificate } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

library.add(
    faPlayCircle,
    faBooks,
    faBookOpen,
    faClosedCaptioning,
    farCircle,
    fasCircle,
    faCertificate,
    faThumbsUp,
    faSpinner,
    faAtom,
    faAtomAlt,
    faChalkboardTeacher,
    faUserUnlock,
    faUserLock
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

import Home from './MathAround';
import Grades from './components/Grades';
import Episodes from './components/Episodes';
import Scene from './components/Scene';
import Admin from './components/Admin';

Vue.config.productionTip = false;

const router = new VueRouter({
    mode: 'abstract',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'grades',
            path: '/grades',
            alias: '/',
            component: Grades
        },
        {
            name: 'episodes',
            path: '/episodes',
            component: Episodes,
            props: true
        },
        {
            name: 'scene',
            path: '/scene',
            component: Scene,
            props: true
        },
        {
            name: 'admin',
            path: '/admin',
            component: Admin
        }
    ]
});

import _madata from './assets/data.json';

const madVuexModule = {
    firestorePath: '/users/{userId}',
    firestoreRefType: 'doc',
    moduleName: 'mad',
    statePropName: 'cloud', // https://mesqueeb.github.io/vuex-easy-firestore/query-data.html#sync-directly-to-module-state
    namespaced: true, // automatically added
    sync: {
        // https://mesqueeb.github.io/vuex-easy-firestore/extra-features.html#fillables-and-guard
        // fillables: ['accountInfo', 'currentGradeNum', 'currentEpisodeNum', 'currentSceneNum', 'progress', 'prefs'] // only these props sync
        // guards: [] // these props do not sync
    },
    state: {
        user: {
            isAnonymous: null,
            uid: null,
            email: null,
            emailVerified: null,
            displayName: null,
            photoURL: null
        },
        content: _madata,
        cloud: {
            currentGradeNum: null,
            currentEpisodeNum: null,
            currentSceneNum: null,
            progress: {
                // this is the latest progress, not a historical log of usage
                // TODO: sync to a server, tied to username; how to deal with trials? -> an account like paid users have (server enforces usage rules)
                /*
                g0: {
                    e1: {
                        completed: t/null, // true after completing once, and stays true
                        sceneList = [1, 2, 3, ...],  // supports insertion and removal to implement REPETITIVE PRACTICE ALGORITHM
                        // the progress of all scenes in an episode get cleared when starting a new episode
                        s1:{
                            correctAtFirstAttempt: t/f,
                            // no "correct" key means hasn't attempted this scene yet
                        }
                    }
                }
                */
            },
            prefs: {
                captioning: true
            }
        }
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        isUserAuthenticated: (state) => {
            return state.user && state.user.uid && !state.user.isAnonymous;
        },
        grades: (state) => {
            // TODO: restrict anonymous users to first episodes
            return state.content.grades;
        },
        currentGradeNum: (state) => {
            return state.cloud.currentGradeNum;
        },
        currentEpisodeNum: (state) => {
            return state.cloud.currentEpisodeNum;
        },
        currentSceneNum: (state) => {
            return state.cloud.currentSceneNum;
        },
        captioning: (state) => {
            return state.cloud.prefs.captioning;
        },
        progress: (state) => {
            return state.cloud.progress;
        }
    },
    actions: {
        async fetchUser({ commit }, user) {
            console.log('fetchUser', user);
            if (user) {
                await this.dispatch('mad/closeDBChannel', { clearModule: true }); // clearModule resets vuex (https://mesqueeb.github.io/vuex-easy-firestore/query-data.html#realtime-updates-opendbchannel)
                console.log('closed DB channel');
                this.dispatch('mad/openDBChannel', { includeMetadataChanges: true }).then(({ refreshed, streaming }) => {
                    console.log('opened DB channel');
                    // ensure main doc is present
                    commit('SET_USER', {
                        isAnonymous: user.isAnonymous,
                        uid: user.uid,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        displayName: user.displayName,
                        photoURL: user.photoURL
                    });
                    refreshed.then(() => {
                        console.log('REFRESHED THEN -- new data?');
                    });
                    streaming.then(() => {
                        console.log('STREAMING THEN -- closed channel?');
                    });
                });
                firebase.analytics().setUserProperties({ uid: user.uid });
                firebase.analytics().logEvent('login', { method: user.isAnonymous ? 'anonymous' : 'registered' });
            }
        }
    },
    mutations: {
        SET_USER(state, data) {
            console.log('SET_USER', data);
            state.user = data;
            console.log('state.user', state.user);
            this.dispatch('mad/set', { accountInfo: { lastAuthn: firebase.firestore.Timestamp.now() } });
        },
        setCaptioning(state, captionsOn) {
            state.cloud.prefs.captioning = captionsOn;
            this.dispatch('mad/set', { prefs: state.cloud.prefs });
        },
        setCurrentGradeNum(state, gradeNum) {
            gradeNum = parseInt(gradeNum, 10);
            let movingToNewGrade = gradeNum !== state.cloud.currentGradeNum;
            state.cloud.currentGradeNum = gradeNum;

            let gradeKey = 'g' + state.cloud.currentGradeNum;
            if (!(gradeKey in state.cloud.progress)) {
                console.debug('initializing state.cloud.progress.' + gradeKey);
                state.cloud.progress[gradeKey] = {};
            }

            // if moving to a new episode, reset episodeNum and sceneNum
            if (movingToNewGrade) {
                state.cloud.currentEpisodeNum = null;
                state.cloud.currentSceneNum = null;
            }
            this.dispatch('mad/set', {
                progress: state.cloud.progress,
                currentGradeNum: state.cloud.currentGradeNum,
                currentEpisodeNum: state.cloud.currentEpisodeNum,
                currentSceneNum: state.cloud.currentSceneNum
            });
        },
        setCurrentEpisodeNum(state, episodeNum) {
            episodeNum = parseInt(episodeNum, 10); // make sure it's an integer
            let movingToNewEpisode = episodeNum !== state.cloud.currentEpisodeNum;
            state.cloud.currentEpisodeNum = episodeNum;

            let gradeKey = 'g' + state.cloud.currentGradeNum;
            let episodeKey = 'e' + state.cloud.currentEpisodeNum;
            if (!(episodeKey in state.cloud.progress[gradeKey]) || movingToNewEpisode) {
                console.debug('initializing state.cloud.progress.' + gradeKey + '.' + episodeKey);
                state.cloud.progress[gradeKey][episodeKey] = { sceneList: [] };
                // populate initial scene list
                state.cloud.progress[gradeKey][episodeKey].sceneList = Object.keys(state.content.grades[state.cloud.currentGradeNum].episodes[episodeNum].scenes).map(Number); // convert data.json string keys to integers
            }

            // if moving to a new episode, reset the sceneNum to 1
            if (movingToNewEpisode) {
                state.cloud.currentSceneNum = 1;
                console.debug('new episode ' + episodeNum + ' ' + state.cloud.currentEpisodeNum);
            } else {
                if (state.cloud.currentSceneNum) {
                    console.debug('resuming scene ' + state.cloud.currentSceneNum);
                    // TODO ???
                } else {
                    state.cloud.currentSceneNum = 1;
                    console.debug('starting fresh');
                }
            }
            this.dispatch('mad/set', {
                progress: state.cloud.progress,
                currentEpisodeNum: state.cloud.currentEpisodeNum,
                currentSceneNum: state.cloud.currentSceneNum
            });
        },
        // setCurrentSceneNum(state, sceneNum) {
        //     state.cloud.currentSceneNum = sceneNum;
        // },
        incrementCurrentSceneNum(state, correctAtFirstAttempt) {
            console.debug('*** incrementCurrentSceneNum', correctAtFirstAttempt);
            let gradeKey = 'g' + state.cloud.currentGradeNum;
            let episodeKey = 'e' + state.cloud.currentEpisodeNum;
            let sceneKey = 's' + state.cloud.currentSceneNum;
            // record progress
            if (!(correctAtFirstAttempt === undefined)) {
                if (state.cloud.progress[gradeKey][episodeKey][sceneKey] === undefined || null) {
                    state.cloud.progress[gradeKey][episodeKey][sceneKey] = {};
                }
                // record correct/wrong state
                state.cloud.progress[gradeKey][episodeKey][sceneKey].correctAtFirstAttempt = correctAtFirstAttempt;

                // REPETITIVE PRACTICE ALGORITHM (i.e., repeat until correct)
                // if wrong, increment # of times wrong
                if (correctAtFirstAttempt === false) {
                    if (state.cloud.progress[gradeKey][episodeKey][sceneKey].wrongAttemptNum === undefined) {
                        state.cloud.progress[gradeKey][episodeKey][sceneKey].wrongAttemptNum = 0;
                    }
                    state.cloud.progress[gradeKey][episodeKey][sceneKey].wrongAttemptNum += 1;

                    switch (state.cloud.progress[gradeKey][episodeKey][sceneKey].wrongAttemptNum) {
                        case 1:
                            console.debug(`got ${sceneKey} wrong 1 time -> inserting after next 2 scenes.`);
                            state.cloud.progress[gradeKey][episodeKey].sceneList.splice(3, 0, state.cloud.currentSceneNum);
                            break;
                        case 2:
                            console.debug(`got ${sceneKey} wrong 2 times -> inserting after next scene.`);
                            state.cloud.progress[gradeKey][episodeKey].sceneList.splice(2, 0, state.cloud.currentSceneNum);
                            break;
                        case 3:
                            console.debug(`got ${sceneKey} wrong 3 times -> retrying scene immediately.`);
                            state.cloud.progress[gradeKey][episodeKey].sceneList.splice(1, 0, state.cloud.currentSceneNum);
                            break;
                        default:
                            console.debug(`got ${sceneKey} wrong 3 times -> skipping`);
                    }
                }
            }

            // remove current scene from sceneList
            state.cloud.progress[gradeKey][episodeKey].sceneList.shift();

            // if no more scenes, route to episode screen
            if (state.cloud.progress[gradeKey][episodeKey].sceneList.length === 0) {
                // reset current episode progress data
                console.debug('no more scenes, going to episodes');
                state.cloud.progress[gradeKey][episodeKey] = {};
                state.cloud.progress[gradeKey][episodeKey].completed = true;
                state.cloud.progress[gradeKey][episodeKey].sceneList = [];
                state.cloud.currentEpisodeNum = null;
                state.cloud.currentSceneNum = null;
                router.replace({ name: 'episodes' });
            } else {
                // go to next scene
                console.debug('progressing to next scene');
                let nextSceneNum = state.cloud.progress[gradeKey][episodeKey].sceneList[0];
                if (state.cloud.currentSceneNum === nextSceneNum) {
                    // just setting the currentSceneNum var doesn't help if the value doesn't change! so we need to broadcast an event
                    this.$vueApp.$emit('reloadCurrentScene');
                } else {
                    state.cloud.currentSceneNum = nextSceneNum;
                }
            }

            this.dispatch('mad/set', {
                progress: state.cloud.progress,
                currentEpisodeNum: state.cloud.currentEpisodeNum,
                currentSceneNum: state.cloud.currentSceneNum
            });
        }
    }
};

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        console.log(`firebase auth logged in uid=${user.uid} anon=${user.isAnonymous} email=${user.email} name=${user.displayName}`);

        LogRocket.identify(user.uid, {
            name: user ? user.displayName : '',
            email: user ? user.email : ''
        });
        // TODO: block use until email is verified ** OR... ** give a grace period of, say, 2 weeks
        if (!user.isAnonymous) {
            if (!user.emailVerified) {
                // TODO: do this just once, or perhaps once a day.
                console.warn('email not verified', user.email);
                // user.sendEmailVerification()
                //     .then(() => {
                //         console.log('email verification sent for', user.uid);
                //     })
                //     .catch((error) => {
                //         console.error('email verification sending failed for', user.uid, error);
                //     });
            } else {
                console.debug('email verified', user.email);
            }
        }
        store.dispatch('mad/fetchUser', user);
    } else {
        console.log('firebase auth logged out');
        store.dispatch('mad/closeDBChannel', { clearModule: true });
        firebase
            .auth()
            .signInAnonymously()
            .catch(function (error) {
                let errorCode = error.code;
                let errorMessage = error.message;
                console.log('signInAnonymously() error', errorCode, errorMessage);
            });
        store.dispatch('mad/fetchUser', null);
    }
});

let easyFirestore = VuexEasyFirestore([madVuexModule], {
    logging: true,
    FirebaseDependency: firebase
});

let store = new Vuex.Store({
    // modules: { mad: madVuexModule }
    plugins: [easyFirestore]
});

firebase
    .firestore()
    .enablePersistence()
    .then(() => {
        console.log('firebase offline mode enabled');
    })
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be
            // enabled in one tab at a a time.
            console.error('firebase offline persistence failed: multiple tabs open');
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of
            // the features required to enable persistence
            console.error('firebase offline persistence failed: unsupported by browser');
        }
    });

// eslint-disable-next-line no-unused-vars
let vueApp = new Vue({
    router,
    store,
    render: (h) => h(Home),
    data: {},
    methods: {
        // TODO: add swipe gesture nav for touch screens
        escapeKeyListener: function (keyEvent) {
            if (this.$route.name === 'scene' && keyEvent.shiftKey) {
                if (keyEvent.code === 'ArrowRight') {
                    console.log('right');
                    this.$store.commit('mad/incrementCurrentSceneNum');
                }
                keyEvent.preventDefault();
            }
        }
    },
    created: function () {
        document.addEventListener('keyup', this.escapeKeyListener);
    },
    mounted: () => {},
    destroyed: function () {
        document.removeEventListener('keyup', this.escapeKeyListener);
    }
}).$mount('#app');

store.$vueApp = vueApp;

const isMobile = () => {
    let ua = navigator.userAgent;
    return ua.match(/(Mobi|Tablet)/);
};
export default isMobile;

router.replace('/grades');
