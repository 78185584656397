<template>
    <div class="container-fluid">
        <div id="choiceMatter" class="row mx-5 align-items-start">
            <div class="choicebox p-0 mr-4 col animated" :class="choiceClasses(0)" v-bind:style="imgBgStyle" @click="madeChoice(0)">
                <img v-bind:src="'/choiceimg/' + choices[0].filename" class="choice img-fluid" />
            </div>
            <div class="choicebox p-0 mr-4 col animated" :class="choiceClasses(1)" v-bind:style="imgBgStyle" @click="madeChoice(1)">
                <img v-bind:src="'/choiceimg/' + choices[1].filename" class="choice img-fluid" />
            </div>
            <div class="choicebox p-0 col animated" :class="choiceClasses(2)" v-bind:style="imgBgStyle" @click="madeChoice(2)">
                <img v-bind:src="'/choiceimg/' + choices[2].filename" class="choice img-fluid" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
var _shuffle = require('lodash/shuffle');

import { Howl } from 'howler';

let soundCorrect = null;
let soundWrong = null;

let playChoiceSfx = function (correct) {
    if (!soundCorrect || soundCorrect.state() == 'unloaded') {
        soundCorrect = new Howl({
            src: `/audio/sfx/SFX_CA.mp3`,
            preload: true
        });
    }
    if (!soundWrong || soundWrong.state() == 'unloaded') {
        soundWrong = new Howl({
            src: `/audio/sfx/SFX_WA.mp3`,
            preload: true
        });
    }
    let soundToPlay = correct ? soundCorrect : soundWrong;
    soundToPlay.play();
};

export default {
    name: 'Choices',
    mounted() {
        this.reload = () => {
            console.debug(`Choices::on:reloadCurrentScene g${this.currentGradeNum}e${this.currentEpisodeNum}s${this.currentSceneNum}`);
            this.shuffleChoices();
            this.madeWrongChoice = false;
        };
        this.$root.$on('reloadCurrentScene', this.reload);
    },
    beforeDestroy() {
        this.$root.$off('reloadCurrentScene', this.reload);
    },
    data() {
        return {
            choices: [],
            choice0result: null, // results: null=default state, 1=selecting, 2=correct, 3=wrong
            choice1result: null,
            choice2result: null,
            madeWrongChoice: false // true if any wrong choice was made
        };
    },
    computed: {
        ...mapGetters('mad', ['grades', 'currentGradeNum', 'currentEpisodeNum', 'currentSceneNum']),
        imgBg: function () {
            return this.grades[this.currentGradeNum].episodes[this.currentEpisodeNum].scenes[this.currentSceneNum].bg;
        },
        imgBgStyle: function () {
            if (this.imgBg) {
                return {
                    'background-image': `url('/choiceimg/${this.imgBg}')`,
                    'background-size': '100% auto'
                };
            }
            return {};
        }
    },
    watch: {
        '$store.state.mad.cloud.currentSceneNum': {
            immediate: true,
            handler() {
                this.shuffleChoices();
                this.madeWrongChoice = false;
            }
        }
    },
    methods: {
        choiceClasses: function (choiceNum) {
            let choiceVar = this[`choice${choiceNum}result`];
            // let clicked = choiceVar === true || choiceVar === false; // i.e., choiceVar!==undefined
            return {
                fadeInRight: choiceVar === null,
                correct: choiceVar === 2,
                wrong: choiceVar === 3,
                selecting: choiceVar === 1,
                // pulse: clicked && choiceVar === false, // wrong answer
                // bounceIn: clicked && choiceVar === true, // correct answer
                fadeOutLeft: this.choice0result === 2 || this.choice1result === 2 || this.choice2result === 2
            };
        },
        madeChoice: function (choiceNum) {
            if (this['choice' + choiceNum + 'result'] > 1) {
                return;
            }

            // TODO: compute elapsed time
            console.debug(
                `g${this.currentGradeNum}e${this.currentEpisodeNum}s${this.currentSceneNum}:` + (this.choices[choiceNum].isCorrect ? 'correct' : 'wrong') + ' time:'
            );

            // mark result (to affect red/green choice highlight via dynamic css class)
            this['choice' + choiceNum + 'result'] = this.choices[choiceNum].isCorrect ? 2 : 3;

            if (this.choices[choiceNum].isCorrect) {
                playChoiceSfx(true);
                // TODO: stop/reset timer

                let correctAtFirstAttempt = this.madeWrongChoice === false;

                setTimeout(() => {
                    this.$store.commit('mad/incrementCurrentSceneNum', correctAtFirstAttempt);
                }, 1000);
            } else {
                playChoiceSfx(false);
                this.madeWrongChoice = true;
            }
        },
        shuffleChoices: function () {
            // compute the filenames of the 3 img choices
            let prefix = `g${this.currentGradeNum}e${this.currentEpisodeNum}s${this.currentSceneNum}`;
            // randomize their order but remember the correct choice!
            this.choices[0] = { isCorrect: true, filename: prefix + 'c.png' };
            this.choices[1] = { isCorrect: false, filename: prefix + 'd1.png' };
            this.choices[2] = { isCorrect: false, filename: prefix + 'd2.png' };
            this.choices = _shuffle(this.choices);
            this.choice0result = null;
            this.choice1result = null;
            this.choice2result = null;
            // TODO: start timer
        }
    }
};
</script>

<style scoped type="scss">
div.choicebox {
    background-color: #fff;
    box-shadow: 0.125rem 0.125rem 0.4rem 0.075rem gray;
    border-radius: 1rem;
}
img.choice {
    border-radius: 1rem;
}
div.choicebox.wrong::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(0, #f00, #f00);
    opacity: 0.5;
    border-radius: 1rem;
}
div.choicebox.correct::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(0, #0f0, #0f0);
    opacity: 0.5;
    border-radius: 1rem;
}
</style>
