<template>
    <div>
        <!-- Modal -->
        <div id="myModal" class="modal modal-fullscreen" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <video ref="videoPlayer" class="video-js"></video>
                    </div>
                </div>
            </div>
        </div>
        <span v-on:click="playVideo()">
            <slot> </slot>
        </span>
    </div>
</template>

<script>
import videojs from 'video.js';
var $ = require('jquery');

export default {
    name: 'VideoPlayer',
    props: {
        videoSrc: String
    },
    computed: {
        videoOptions: function() {
            return {
                autoplay: false,
                controls: true,
                fluid: true,
                aspectRatio: '16:9',
                responsive: true,
                sources: [
                    {
                        src: this.videoSrc
                    }
                ],
                bigPlayButton: true,
                controlBar: {
                    pictureInPictureToggle: false,
                    fullscreenToggle: false
                }
            };
        }
    },
    data() {
        return {
            player: null
        };
    },
    mounted() {
        $('#myModal').on(
            'hide.bs.modal',
            function() {
                this.player.pause();
                this.player.currentTime(0);
            }.bind(this)
        );

        this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {
            //console.debug('video player ready', this);
        });
        // video.js events: https://github.com/videojs/video.js/blob/f711ddcfb644b91da4c1f67b04bff081d42157a7/src/js/player.js#L1182
        this.player.on('play', function() {
            //console.debug('on play');
        });
        this.player.on('ended', function() {
            //console.debug('ended');
            $('#myModal').modal('hide');
        });
        this.player.on('fullscreenchange', function() {
            //console.debug('fullscreenchange');
            if (!this.isFullscreen()) {
                $('#myModal').modal('hide');
            }
        });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    methods: {
        playVideo: function() {
            //console.debug('playVideo');

            if (!this.player.paused()) {
                return;
            }

            let promise = this.player.play();

            if (promise !== undefined) {
                promise
                    .then(
                        function() {
                            //console.debug('started');
                            $('#myModal').modal('show');
                        }.bind(this)
                    )
                    .catch(function(error) {
                        console.error('failed');
                        console.error(error);
                    });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    &.modal-fullscreen {
        .modal-dialog {
            width: 60vw;
            margin: 0 auto;
            padding: 0;
            max-width: none;
        }

        .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
            border: none;
        }
    }
}

.modal-backdrop {
}
</style>
