<template>
    <div class="container" style="padding-top: 40px;">
        <div id="backToGradesBtn" class="position-fixed">
            <router-link to="/" replace><font-awesome-icon :icon="['fad', 'books']" size="3x" class="theme-maicons" /></router-link>
        </div>

        <div class="row justify-content-center">
            <div v-if="isUserAuthenticated" id="accountInfo" class="col-md-8">
                <!-- TODO: show logout link -->
                <div class="card">
                    <div class="card-header">
                        Account
                        <button type="button" class="float-right btn btn-sm btn-secondary" @click="logout">Logout</button>
                    </div>
                    <div class="card-body">
                        <h4>Hello {{ user.displayName || '' }} <img v-if="user.photoURL" :src="user.photoURL" style="max-height: 2rem;" /><br /></h4>
                        <small>{{ user.email }}</small>
                    </div>
                </div>
            </div>
            <div v-else-if="!authChosen">
                <div class="card">
                    <div class="card-header">Sign in or Register</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <button class="w-100 btn btn-primary text-nowrap" @click="authChosen = true">I have an account</button>

                            </div>
                            <div class="col-6">
                                <button class="w-100 btn btn-primary text-nowrap" @click="authChosen = registering = true">Try it Free!</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else id="login" class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <span v-if="!registering">Sign In</span>
                        <span v-else>Register for MathAround // Free to Try for a Limited Time</span>
                    </div>
                    <div class="card-body">
                        <div class="pb-4">
                            <a v-if="!registering" href="#" @click="registering = true"><small>or try for free</small></a>
                            <a v-else href="#" @click="registering = false"><small>or sign in if you have an account</small></a>
                        </div>
                        <div class="text-center mb-4">
                            <div class="row justify-content-center">
                                <div class="col text-right">
                                    <button class="btn btn-outline-dark shadow-sm loginbtn google mx-1 mr-3" type="button" @click="loginGoogle">
                                        <img class="loginicon" src="../ui/google.svg" /><span>Sign in with Google</span>
                                    </button>
                                </div>
                                <div class="col text-left">
                                    <button class="btn btn-outline-dark shadow-sm loginbtn microsoft" type="button" @click="loginMicrosoft">
                                        <img class="loginicon" src="../ui/microsoft.svg" /><span>Sign in with Microsoft</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div v-if="error" class="alert alert-danger">{{ error }}</div>
                        <form action="#" @submit.prevent="submit">
                            <div class="form-group row">
                                <label for="username" class="col-md-4 col-form-label text-md-right">Email</label>

                                <div class="col-md-6">
                                    <input id="username" type="email" class="form-control" name="username" value required autofocus v-model="signInUpForm.username" />
                                </div>
                            </div>

                            <div v-if="registering" class="form-group row">
                                <label for="name" class="col-md-4 col-form-label text-md-right">Name</label>

                                <div class="col-md-6">
                                    <input id="name" type="name" class="form-control" name="name" value required v-model="signInUpForm.name" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" name="password" required v-model="signInUpForm.password" />
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-2 offset-md-4">
                                    <button type="button" class="btn btn-primary text-nowrap" @click="loginOrRegister">
                                        <span v-if="!registering">Sign In</span>
                                        <span v-else>Register</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import { mapGetters } from 'vuex';
import isMobile from '../main';

export default {
    name: 'Admin',
    data() {
        return {
            authChosen: false,
            registering: false,
            signInUpForm: {
                username: '',
                password: '',
                name: ''
            },
            error: null
        };
    },
    computed: {
        ...mapGetters('mad', ['user', 'isUserAuthenticated'])
    },
    methods: {
        loginOrRegister() {
            console.debug('loginOrRegister');

            if (this.registering) {
                if (!this.signInUpForm.name) {
                    this.error = 'Please enter your name.';
                } else {
                    firebase
                        .auth()
                        .createUserWithEmailAndPassword(this.signInUpForm.username, this.signInUpForm.password)
                        .then((data) => {
                            console.log('updating name', this.signInUpForm.name);
                            data.user
                                .updateProfile({
                                    displayName: this.signInUpForm.name
                                })
                                .then(() => {});
                            this.$router.replace({ name: 'grades' });
                        })
                        .catch((err) => {
                            this.error = err.message;
                        });
                }
            } else {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(this.signInUpForm.username, this.signInUpForm.password)
                    .then(() => {
                        this.$router.replace({ name: 'grades' });
                    })
                    .catch((err) => {
                        console.error('signIn error', err);
                        if (err.code === 'auth/user-not-found') {
                            this.registering = true;
                        } else {
                            this.error = err.message;
                        }
                    });
            }
        },
        loginGoogle() {
            const provider = new firebase.auth.GoogleAuthProvider();
            if (isMobile()) {
                firebase
                    .auth()
                    .signInWithRedirect(provider)
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    // eslint-disable-next-line no-unused-vars
                    .then((result) => {
                        // TODO: for providers like this, auto-verify the email address -> need to use a CloudFunction & Admin SDK (https://stackoverflow.com/a/60230060)
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        // let token = result.credential.accessToken;

                        // let user = result.user;

                        this.$router.replace({ name: 'grades' });
                    })
                    .catch((err) => {
                        this.error = err.message;
                    });
            }
        },
        loginMicrosoft() {
            let provider = new firebase.auth.OAuthProvider('microsoft.com');
            if (isMobile()) {
                firebase
                    .auth()
                    .signInWithRedirect(provider)
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                firebase
                    .auth()
                    .signInWithPopup(provider)
                    // eslint-disable-next-line no-unused-vars
                    .then((result) => {
                        // TODO: for providers like this, auto-verify the email address
                        // User is signed in.
                        // IdP data available in result.additionalUserInfo.profile.
                        // OAuth access token can also be retrieved:
                        // result.credential.accessToken
                        // OAuth ID token can also be retrieved:
                        // result.credential.idToken
                        // TODO: persist like everything in result (accessToken, profile data, etc.); why? I don't know.
                        this.$router.replace({ name: 'grades' });
                    })
                    .catch((err) => {
                        this.error = err.message;
                    });
            }
        },
        logout: () => {
            console.log('logging out...');
            firebase
                .auth()
                .signOut()
                .then(() => {
                    console.log('LOGGED OUT');
                    // window.location.reload(); // another big hammer
                });
        }
    }
};
</script>

<style lang="scss">
#backToGradesBtn {
    top: 0;
    left: 0;
    padding: 40px 0 0 12px;
}
div.card{
    min-width: 30rem;
}
button.loginbtn {
    font-family: Roboto, sans serif;
    font-size: 1rem;
    border-radius: 2px;
    border: none;
    color: #fff;
    padding: 0 0.5rem 0 0;
    height: calc(2rem + 2px);
    > span {
        vertical-align: middle;
        padding-bottom: 0.1rem;
        /*text-shadow: 1px 1px 3px #333;*/
    }
    &.google {
        border: 1px solid #4285F4;
        background-color: #4285F4;
        > img.loginicon {
            background-color: white !important;
        }
    }
    &.microsoft {
        background-color: #2f2f2f;
    }
}
img.loginicon {
    height: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-right: 0.5rem;
}
</style>
