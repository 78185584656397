<template>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" appear>
        <div class="d-flex flex-column flex-grow-1">
            <div class="position-fixed" style="top: 40px; right: 20px;">
                <div>
                    <router-link to="/admin" replace>
                        <div v-if="isUserAuthenticated">
                            <img v-if="user.photoURL" :src="user.photoURL" style="max-height: 2rem;" />
                            <div v-else class="text-center">
                                <font-awesome-icon :icon="['fad', 'user-lock']" transform="grow-8" class="theme-maicons"></font-awesome-icon><br/>
                                <small>{{user.displayName}}</small>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <font-awesome-icon :icon="['fad', 'user-unlock']" transform="grow-8" class="theme-maicons"></font-awesome-icon><br />
                            sign in
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="row m-0 px-0 align-items-center">
                <div class="col d-none d-md-block p-0 header-extra text-right" style="z-index: -1000;">
                    <span>For kids on the planet</span>
                </div>
                <div class="col text-center">
                    <transition appear name="fade">
                        <img src="../ui/logo.svg" class="py-lg-1" style="max-height: 10vh;" />
                    </transition>
                </div>
                <div class="col d-none d-md-block p-0 header-extra text-left" style="z-index: -1000;">
                    It goes around the world
                </div>
            </div>

            <div class="row m-0 gradegrid justify-content-center">
                <!--  text-center align-items-stretch -->
                <div
                    class="grade m-2"
                    style="position: relative;"
                    v-on:click="gradeChosen0 = true"
                    :class="{ 'pulse animated': gradeChosen0 }"
                    @animationend="showEpisodeList(0)"
                >
                    <div class="card text-white">
                        <img class="gci card-img" v-bind:src="'/covers/g0.jpg'" alt="Cover Image" />
                        <div class="card-img-overlay d-flex flex-column justify-content-end">
                            <h4 class="card-title">{{ grades[0].title }}</h4>
                        </div>
                    </div>
                    <div class="grade-indicator">
                        <font-awesome-icon :icon="['far', 'circle']" class="text-danger" transform="shrink-6" />
                    </div>
                </div>
                <div
                    class="grade m-2"
                    style="position: relative;"
                    v-on:click="gradeChosen1 = true"
                    :class="{ 'pulse animated': gradeChosen1 }"
                    @animationend="showEpisodeList(1)"
                >
                    <div class="card text-white">
                        <img class="gci card-img" v-bind:src="'/covers/g1.jpg'" alt="Cover Image" />
                        <div class="card-img-overlay d-flex flex-column justify-content-end">
                            <h4 class="card-title">{{ grades[1].title }}</h4>
                        </div>
                    </div>
                    <div class="grade-indicator">
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                    </div>
                </div>
            </div>

            <div class="row m-0 gradegrid justify-content-center">
                <!--  text-center align-items-stretch -->
                <div
                    class="grade m-2"
                    style="position: relative;"
                    v-on:click="gradeChosen2 = true"
                    :class="{ 'pulse animated': gradeChosen2 }"
                    @animationend="showEpisodeList(2)"
                >
                    <div class="card text-white">
                        <img class="gci card-img" v-bind:src="'/covers/g2.jpg'" alt="Cover Image" />
                        <div class="card-img-overlay d-flex flex-column justify-content-end">
                            <h4 class="card-title">{{ grades[2].title }}</h4>
                        </div>
                    </div>
                    <div class="grade-indicator">
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                    </div>
                </div>
                <div
                    class="grade m-2"
                    style="position: relative;"
                    v-on:click="gradeChosen3 = true"
                    :class="{ 'pulse animated': gradeChosen3 }"
                    @animationend="showEpisodeList(3)"
                >
                    <div class="card text-white">
                        <img class="gci card-img" v-bind:src="'/covers/g3.jpg'" alt="Cover Image" />
                        <div class="card-img-overlay d-flex flex-column justify-content-end">
                            <h4 class="card-title">{{ grades[3].title }}</h4>
                        </div>
                    </div>
                    <div class="grade-indicator">
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                        <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="shrink-6" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('mad');

export default {
    name: 'Grades',
    data() {
        return {
            gradeChosen0: false,
            gradeChosen1: false,
            gradeChosen2: false,
            gradeChosen3: false
        };
    },
    computed: {
        ...mapGetters(['grades', 'currentGradeNum', 'user', 'isUserAuthenticated'])
    },
    methods: {
        showEpisodeList: function (gradeNum) {
            this.gradeChosen0 = this.gradeChosen1 = this.gradeChosen2 = this.gradeChosen3 = false;
            this.$store.commit('mad/setCurrentGradeNum', gradeNum);
            // console.debug('new currentGradeNum ' + this.currentGradeNum);
            console.debug('routing to g' + this.currentGradeNum);
            this.$router.replace({ name: 'episodes' });
        }
    }
};
</script>

<style scoped lang="scss">
@import '../matharound';
div {
    /*border: 1px solid red;*/
}

.header-extra {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: $cetati-dkblue;
}

.gci {
    width: auto;
    height: auto;
    max-height: 35vh;
    max-width: 40vw;
}

div.card {
    background-color: transparent;
    width: auto;
    height: auto;
    max-height: 35vh;
    max-width: 40vw;
    border: none;
}

.card-img-overlay {
    padding: 0;
    margin: 0;
}

.card-title {
    font-family: Caveat;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 1.15rem;
    text-shadow: black 0.15rem 0.15rem 0.2rem;
    background-image: linear-gradient(to bottom, rgba(70, 70, 70, 0.2), rgba(70, 70, 70, 0.7));
    backdrop-filter: blur(10px);
    border-radius: 0 0 0.25rem 0.25rem;
}

div.gradegrid {
}
div.grade {
    cursor: pointer;
}

div.grade-indicator {
    border-radius: 0.25rem 0 0.25rem 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.1rem 0.2rem;
    line-height: 0.5rem;
    background: rgba(50, 50, 50, 0.3);
}

/*div.dots {*/
/*    background-color: red;*/
/*    position: absolute;*/
/*    bottom: .5rem;*/
/*    right: .5rem;*/
/*    width: 4rem;*/
/*    height: 2rem;*/
/*    font-family: Roboto;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    display: flex;*/
/*    align-items: flex-end;*/
/*    text-align: center;*/
/*    color: #000000;*/
/*}*/
</style>
