<template>
    <div id="appcontainer" class="d-flex flex-column min-vh-100 pt-2 px-2 pb-0">
        <router-view />
        <div id="sitefooter"><router-link to="/" replace>MathAround</router-link>&trade; is &copy; 2023 Cetati Inc.</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MathAround',
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('mad', ['user', 'isUserAuthenticated'])
    },
    methods: {}
};
</script>

<style lang="scss">
@import 'matharound';

#sitefooter {
    font-size: 0.7rem;
    color: $cetati-green2;
    padding: 0.3rem 0;
    text-align: center;
}
</style>
