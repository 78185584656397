<template>
    <div id="sentenceMatter" class="row align-items-center pt-0 pb-2 pb-xs-3 pt-lg-4 pb-lg-5">
        <div class="col-2 text-right p-0">
            <div id="playbutton" class="" v-on:click="narrate(false, false)">
                <font-awesome-icon :icon="['fad', 'play-circle']" swap-opacity class="theme-maicons" :class="{ 'pulse infinite animated': isNarrating }" />
            </div>
        </div>
        <transition enter-active-class="animated lightSpeedIn" appear>
            <h2 id="sentenceText" class="col pr-5 mr-2 text-responsive" v-show="captioning" :key="sentence">{{ sentence }}</h2>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Howl } from 'howler';

export default {
    name: 'SentenceMatter',
    mounted() {
        this.reload = () => {
            console.debug(`SentenceMatter::on:reloadCurrentScene g${this.currentGradeNum}e${this.currentEpisodeNum}s${this.currentSceneNum}`);
            if (this.sound) {
                this.sound.stop();
            }
            this.narrate(false);
        };
        this.$root.$on('reloadCurrentScene', this.reload);
    },
    beforeDestroy() {
        console.debug('SentenceMatter.beforeDestroy');
        this.$root.$off('reloadCurrentScene', this.reload);
        if (this.sound) {
            this.sound.unload();
        }
    },
    props: {
        sentence: String
    },
    data() {
        return {
            sound: null,
            isNarrating: false
        };
    },
    computed: {
        ...mapGetters('mad', ['currentGradeNum', 'currentEpisodeNum', 'currentSceneNum', 'captioning'])
    },
    watch: {
        sentence: {
            immediate: true, // do this on component creation, too
            handler() {
                console.debug('SentenceMatter::watch:sentence');
                this.narrate(true, true);
            }
        }
    },
    methods: {
        narrate: function (shouldDelay, shouldInit) {
            // In the future...
            // If needed (such as jumping to a scene at app startup), audio must be triggered by a user action
            // like pressing a big "GO!" button in a popup modal...
            // https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide

            console.debug(`SentenceMatter::narrate(${this.currentSceneNum})`);

            if (shouldInit) {
                console.debug('INITIALIZING SOUND');
                if (this.sound) {
                    this.sound.unload();
                    this.sound = null;
                }
            }
            if (!this.sound) {
                this.sound = new Howl({
                    src: `/audio/narration/g${this.currentGradeNum}e${this.currentEpisodeNum}s${this.currentSceneNum}.mp3`,
                    preload: true,
                    html5: true,
                    onplay: function () {
                        this.isNarrating = true;
                    }.bind(this),
                    onend: function () {
                        this.isNarrating = false;
                    }.bind(this)
                });
            }

            if (this.sound.playing()) {
                console.debug('SKIPPING new narration since another is currently playing');
                return;
            }

            setTimeout(
                () => {
                    // one last check for previously-playing audio
                    if (!this.sound.playing()) {
                        let id = this.sound.play();
                        console.debug('playing', id, this.sound._src);
                    }
                },
                shouldDelay ? 1000 : 0
            );
        }
    }
};
</script>

<style scoped lang="scss">
@import '../matharound';

#sentenceMatter {
    margin: 0 !important;
}

#playbutton {
    font-size: 4rem;
    cursor: pointer;
    line-height: 1rem;
}
#sentenceText {
    font-family: 'Lato', Arial, sans-serif;
    font-weight: bold;
    letter-spacing: 0.01rem;
    font-variant-ligatures: no-common-ligatures;
    margin-bottom: 0;
}
</style>
