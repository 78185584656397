<template>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" appear>
        <div class="flex-grow-1">
            <div id="backToGradesBtn" class="position-fixed">
                <router-link to="/" replace><font-awesome-icon :icon="['fad', 'books']" size="3x" class="theme-maicons" /></router-link>
            </div>

            <transition appear name="fade">
                <div class="">
                    <div class="text-center display-1">
                        {{ grade.title }}
                    </div>
                </div>
            </transition>

            <!--     TODO: put grade image somewhere (background?) -->

            <div class="griddythegrid pt-3">
                <video-player :videoSrc="grade.videoUrl">
                    <div
                        class="gci h-100 position-relative d-flex align-self-center flex-column align-items-start justify-content-end"
                        v-bind:style="{ backgroundImage: 'url(/covers/g' + currentGradeNum + '.jpg)' }"
                        :class="{ dim: episodeSelected > 0 }"
                    >
                        <div class="position-absolute" style="bottom: 0.3rem; left: 0.3rem; opacity: 0.7;">
                            <font-awesome-icon :icon="['fad', 'play-circle']" size="3x" swap-opacity class="theme-maicons glow" />
                        </div>
                    </div>
                </video-player>

                <div
                    class="gci d-flex flex-column align-items-start justify-content-end"
                    style="position: relative;"
                    v-for="(episode, episodeNum) in grade.episodes"
                    :key="episodeNum"
                    v-on:click="showEpisode(episodeNum)"
                    :style="{
                        backgroundImage: 'url(/covers/g' + currentGradeNum + 'e' + episodeNum + '.png), url(/covers/g' + currentGradeNum + 'e' + episodeNum + 'bg.jpg)'
                    }"
                    :class="{ 'pulse infinite animated': episodeSelected === episodeNum, dim: episodeSelected > 0 && episodeSelected !== episodeNum }"
                >
                    <h5 class="episode-footer">
                        {{ episode.title }}
                    </h5>
                    <div class="position-absolute" style="top: -0.2rem; right: -0.2rem;">
                        <!-- completed -->
                        <span v-if="episodeStateCompleted(episodeNum)" title="completed">
                            <font-awesome-layers full-width class="fa-2x">
                                <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="grow-4" />
                                <font-awesome-icon :icon="['fad', 'thumbs-up']" class="text-success" transform="shrink-2" swap-opacity />
                            </font-awesome-layers>
                        </span>
                        <!-- in progress -->
                        <span v-else-if="episodeStateInProgress(episodeNum)" title="in progress">
                            <font-awesome-layers full-width class="fa-2x">
                                <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="grow-4" />
                                <font-awesome-icon :icon="['fad', 'atom']" class="text-success" pulse />
                            </font-awesome-layers>
                        </span>
                        <!-- new -->
                        <span v-else>
                            <font-awesome-layers full-width class="fa-2x" title="new">
                                <font-awesome-icon :icon="['fas', 'circle']" class="text-danger" transform="grow-4" />
                                <font-awesome-icon :icon="['fas', 'certificate']" class="text-success" />
                                <font-awesome-layers-text class="text-white" transform="shrink-11.5 rotate--30" value="NEW" />
                            </font-awesome-layers>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('mad');
import VideoPlayer from './VideoPlayer';
import { Howl } from 'howler';

export default {
    name: 'Episodes',
    components: { VideoPlayer },
    mounted: function () {},
    beforeDestroy() {
        if (this.sound) {
            this.sound.unload();
        }
    },
    data() {
        return {
            sound: null,
            episodeSelected: 0
        };
    },
    computed: {
        ...mapGetters(['grades', 'currentGradeNum', 'currentEpisodeNum', 'progress']),
        grade: function () {
            return this.grades[this.currentGradeNum];
        },
        videoOptions: function () {
            return {
                autoplay: false,
                controls: true,
                fluid: true,
                aspectRatio: '16:9',
                responsive: true,
                sources: [
                    {
                        src: this.grade.videoUrl
                    }
                ],
                bigPlayButton: true,
                controlBar: {
                    pictureInPictureToggle: false
                }
            };
        }
    },
    methods: {
        showEpisode: function (episodeNum) {
            if (this.episodeSelected > 0) {
                // might be called more than once while audio is playing; this effectively disables click events
                return;
            }
            this.episodeSelected = episodeNum;

            let navigateToEpisode = () => {
                this.sound.unload();
                this.$store.commit('mad/setCurrentEpisodeNum', episodeNum);
                console.debug('routing to g' + this.currentGradeNum + 'e' + this.currentEpisodeNum);
                this.$router.replace({ name: 'scene' });
            };

            // play episode title audio
            this.sound = new Howl({
                src: `/audio/episodeTitles/g${this.currentGradeNum}e${episodeNum}.mp3`,
                html5: true,
                onend: navigateToEpisode
            });
            this.sound.play();
        },
        // episodeStateNew: function(episodeNum) {
        //     return (
        //         !this.progress ||
        //         !this.progress[`g${this.currentGradeNum}`] ||
        //         !this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`] ||
        //         Object.keys(this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`]).length === 0
        //     );
        // },
        episodeStateInProgress: function (episodeNum) {
            return !!(
                this.progress &&
                this.progress[`g${this.currentGradeNum}`] &&
                this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`] &&
                Object.keys(this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`]).length > 0 &&
                !this.episodeStateCompleted(episodeNum)
            );
        },
        episodeStateCompleted: function (episodeNum) {
            return (
                this.progress &&
                this.progress[`g${this.currentGradeNum}`] &&
                this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`] &&
                this.progress[`g${this.currentGradeNum}`][`e${episodeNum}`].completed
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../matharound';
@import '~video.js/dist/video-js.css';

// TODO: change background based on selected grade?
// ex: grab representative repeating backgrounds from https://www.heropatterns.com/

.griddythegrid {
    width: calc(100vw - 1rem);
    padding: 0 60px;
    height: 80vh;
    display: grid;
    grid-gap: 1rem 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
}

.gci {
    cursor: pointer;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.7);
    > .episode-footer {
        color: white;
        width: 100%;
        font-family: Caveat, Serif;
        font-size: 1.4rem;
        font-weight: 400;
        padding: 0rem;
        margin: 0rem;
        text-shadow: black 0.15rem 0.15rem 0.2rem;
        background-image: linear-gradient(to bottom, rgba(70, 70, 70, 0.2), rgba(70, 70, 70, 0.7));
        backdrop-filter: blur(10px);
    }
}

.dim {
    opacity: 0.6;
    filter: blur(8px);
}

#backToGradesBtn {
    top: 0;
    left: 0;
    padding: 40px 0 0 12px;
}

.episode-footer {
    line-height: 1.15rem;
}
</style>
